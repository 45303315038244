import React from "react"
import PropTypes from "prop-types"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"
import Alert from "react-bootstrap/Alert"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
import * as Yup from "yup"
import { useFormik } from "formik"

const styles = {
  disclaimer: {
    fontSize: 12,
  },
}

const formValidationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
})

const SignInForm = ({ onSubmit }) => {
  const [errorMsg, setErrorMsg] = React.useState(null)
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      setErrorMsg(null)
      try {
        await onSubmit(values.email, values.password)
      } catch (err) {
        setErrorMsg(err.message)
      }
    },
  })

  return (
    <>
      {errorMsg ? (
        <Alert style={{ marginBottom: "1em" }} variant="danger">
          {errorMsg}
        </Alert>
      ) : null}
      <Form onSubmit={handleSubmit} className="w-100">
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="you@email.com"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            isInvalid={!!errors.email && !!touched.email}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            isInvalid={!!errors.password && !!touched.password}
          />
        </Form.Group>
        <p className="mt-4" style={styles.disclaimer}>
          <Link className="forgot-password" to="/reset">
            Forgot password?
          </Link>
        </p>
        <Row className="d-flex justify-content-center">
          <Button
            className="mt-4"
            variant="outline-light"
            type="submit"
            disabled={isSubmitting}
            style={{
              cursor: isSubmitting ? "not-allowed" : "pointer",
              minWidth: "10em",
            }}
          >
            {isSubmitting ? (
              <Spinner
                animation="border"
                size="sm"
                as="span"
                role="status"
                aria-hidden="true"
                style={{ marginBottom: 5 }}
              />
            ) : (
              "Sign In"
            )}
          </Button>
        </Row>
      </Form>
    </>
  )
}

SignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SignInForm
