import React from "react"
import { Col, Row, Button, Spinner } from "react-bootstrap"
import { useAuthV2 } from "../../hooks/useAuth"
import { Helmet } from "react-helmet"
import SignUpForm from "../../components/forms/SignUpForm"
import { StaticImage } from "gatsby-plugin-image"
import SignInForm from "../../components/forms/SignInForm"
import { TreeDivider } from "../../components/blocks/TreeDivider"
import TreeCarBackground from "../../images/overcrest/two-car-tree.png"

export default function RallyRegistration({ location }) {
  const { user, authInitialized, authUser, signUp, signIn } = useAuthV2()
  const approvalLinkId = React.useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    return queryParams.get("approval_link")
  }, [location])
  const [form, setForm] = React.useState(null)
  const [showError, setShowError] = React.useState(false)

  React.useEffect(() => {
    const navigateToApprovalLink = async () => {
      setForm("redirecting")
      const idToken = await authUser.getIdToken()
      window.location.href = `/approvals/${approvalLinkId}?id_token=${idToken}`
    }

    if (authUser && user && authInitialized) {
      navigateToApprovalLink()
    }
  }, [user, authInitialized, authUser, approvalLinkId, location, setForm])

  React.useEffect(() => {
    if (approvalLinkId) {
      setForm("sign-up")
    } else {
      setForm("loading")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    let timeout
    // This is to prevent the flicker of the error screen looking odd.
    // Display the error message only after a few seconds of being on screen
    // since reading a query param is quick
    if (form === "loading") {
      timeout = setTimeout(() => {
        setShowError(true)
      }, 4000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [form, setShowError])

  return (
    <Row className="overcrest vh-100">
      <Helmet>
        <body className="bg-tan" />
        <title>Overcrest Rally 2022 Registration</title>
      </Helmet>
      <TreeDivider side="left" lg={3} />
      <Col
        xs={12}
        lg={6}
        style={{
          backgroundImage: `url(${TreeCarBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundSize: "contain",
        }}
      >
        <div
          className="w-100 h-100"
          style={{ backgroundColor: `rgba(245, 240, 220, 0.85)` }}
        >
          <Row
            style={{ height: "33%" }}
            className="d-flex justify-content-center pt-4"
          >
            <StaticImage
              src="../../images/overcrest/oc-rally-22-wordmark-color.png"
              alt="Overcrest Rally 2022 Logo"
              placeholder="blurred"
              layout="constrained"
              aspectRatio={16 / 9}
              objectFit="contain"
              height={200}
              transformOptions={{ fit: "fill" }}
            />
          </Row>
          <Row
            style={{
              height: "66%",
            }}
            className="d-flex justify-content-center w-100 p-0 m-0"
          >
            <Col className="w-100" xs={8}>
              {form === "loading" ? (
                <Row className="justify-content-center">
                  {showError === false ? (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ height: 50, width: 50, color: "#02380C" }}
                    />
                  ) : (
                    <h1>
                      An error has occurred, please try clicking the link that
                      was sent to you again
                    </h1>
                  )}
                </Row>
              ) : null}
              {form === "sign-up" ? (
                <Row>
                  <Col>
                    <Row className="w-100 pb-2 m-0">
                      <h3 className="text-center w-100">
                        Create your Rallista account
                      </h3>
                      <p className="text-center w-100 subheader">
                        If you already have a Rallista account,{" "}
                        <Button
                          variant="link"
                          className="p-0"
                          style={{ verticalAlign: "baseline" }}
                          onClick={() => setForm("sign-in")}
                        >
                          click here to sign in
                        </Button>
                      </p>
                    </Row>
                    <Row className="w-100 m-0">
                      <SignUpForm
                        onSubmit={async (username, email, password) => {
                          try {
                            await signUp(email, password, username)
                          } catch (err) {
                            console.error(err)
                            throw err
                          }
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {form === "sign-in" ? (
                <Row>
                  <Col>
                    <Row className="w-100 pb-2 m-0">
                      <h3 className="text-center w-100">
                        Sign in to your Rallista account
                      </h3>
                      <p className="text-center w-100 subheader">
                        {"If you don't have an account, "}
                        <Button
                          variant="link"
                          className="p-0"
                          style={{ verticalAlign: "baseline" }}
                          onClick={() => setForm("sign-up")}
                        >
                          click here to create one
                        </Button>
                      </p>
                    </Row>
                    <Row className="w-100 m-0">
                      <SignInForm
                        onSubmit={async (email, password) => {
                          await signIn(email, password)
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : null}
              {form === "redirecting" ? (
                <Row>
                  <Col>
                    <Row className="w-100 pb-2 m-0">
                      <h3 className="text-center w-100">
                        Hold tight. Redirecting you to payment...
                      </h3>
                    </Row>
                    <Row className="w-100 m-0 h-100 d-flex justify-content-center align-items-center">
                      <Spinner
                        animation="border"
                        style={{ height: 50, width: 50, color: "#02380C" }}
                      />
                    </Row>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <Row className="d-block d-md-none pt-2 pb-5">
            <div className="d-flex justify-content-center">
              <StaticImage
                src="../../images/overcrest/914.png"
                alt="914 Overcrest Rally"
                placeholder="blurred"
                layout="constrained"
                objectFit="contain"
                aspectRatio={1.53}
                height={200}
                transformOptions={{ fit: "fill" }}
              />
            </div>
          </Row>
        </div>
      </Col>
      <TreeDivider side="right" lg={3} />
    </Row>
  )
}
