import React from "react"
import PropTypes from "prop-types"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"
import Row from "react-bootstrap/Row"
import Alert from "react-bootstrap/Alert"
import { Link } from "gatsby"
import * as Yup from "yup"
import { useFormik } from "formik"

const formValidationSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .min(2, "Username must be at least two characters")
    .max(64, "Username can not be longer than 64 characters")
    .matches(/^[a-z0-9]+(?:[_-][a-z0-9]+)*$/, {
      message: "Your username includes an invalid character",
    })
    .lowercase("The username can only contain lowercase letters")
    .required("Username is required"),
  email: Yup.string().trim().email().required("An email is required"),
  password: Yup.string()
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required(),
})

const styles = {
  submitButton: {
    minWidth: "10em",
  },
  disclaimer: {
    fontSize: 12,
  },
}

const SignUpForm = ({ onSubmit, submitButtonText = "Create Account" }) => {
  const [errorMsg, setErrorMsg] = React.useState(null)
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    dirty,
  } = useFormik({
    initialValues: { username: "", email: "", password: "" },
    validationSchema: formValidationSchema,
    onSubmit: async (values) => {
      setErrorMsg(null)
      try {
        await onSubmit(values.username, values.email, values.password)
      } catch (err) {
        setErrorMsg(err.message)
      }
    },
  })

  return (
    <>
      {errorMsg ? (
        <Alert style={{ marginBottom: "1em" }} variant="danger">
          {errorMsg}
        </Alert>
      ) : null}
      <Form data-testid="signupForm" onSubmit={handleSubmit} className="w-100">
        <Form.Group controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            name="username"
            placeholder="Username"
            autoComplete="off"
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.username && !!touched.username}
            value={values.username}
          />
          <Form.Text className="text-muted">
            The username can only contain lowercase letters, numbers, and a - or
            _
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            {errors.username}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="you@email.com"
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.email && !!touched.email}
            value={values.email}
          />
          <Form.Control.Feedback type="invalid">
            Value is not a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.password && !!touched.password}
            value={values.password}
          />
          <Form.Text className="text-muted">
            Your password must be 8-20 characters long, contain an uppercase
            letter, a lowercase letter, and a number. It cannot contain spaces,
            special characters, or emoji.
          </Form.Text>
        </Form.Group>
        <p className="mt-2 disclaimer" style={styles.disclaimer}>
          By creating an account you agree to our{" "}
          <Link to="/privacy">Privacy Policy</Link> and{" "}
          <Link to="/terms">Terms of Service</Link>.
        </p>
        <Row className="d-flex justify-content-center">
          <Button
            className="mt-4"
            style={{
              ...styles.submitButton,
              cursor: isSubmitting || !dirty ? "not-allowed" : "pointer",
            }}
            variant="outline-light"
            type="submit"
            disabled={!isValid || !dirty}
          >
            {isSubmitting ? (
              <Spinner
                animation="border"
                size="sm"
                as="span"
                role="status"
                aria-hidden="true"
                style={{ marginBottom: 5 }}
              />
            ) : (
              submitButtonText
            )}
          </Button>
        </Row>
      </Form>
    </>
  )
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
}

export default SignUpForm
